/* mirza font */
@import url("https://fonts.googleapis.com/css2?family=Mirza:wght@400;500;600;700&display=swap");
:root {
  --white: #fff;
  --sec_white: #ffffffb5;
  --black: #000;
  --service_gradTop: #12467b;
  --bg_color: #030c15;
  --radiant: #4cd5d9;
  --radiant_green: #5fe44b;
  --gradient_btn: linear-gradient(
    to right,
    var(--radiant), 
    var(--radiant_green)
  );
  --gradient_btnInvert: linear-gradient(
    to right,
    var(--radiant_green),
    var(--radiant)
  );
  --gradient_textRight: #7ee92f;
  --gradient_text: linear-gradient(
    to right,
    var(--radiant) 0%,
    var(--gradient_textRight) 38%
  );
  --bottom_hr: #247e61;
  --accord_border: #8c9693;
  --greenBox_left: #113127;
  --greenBox_right: #0e2619;
  --border: #8c9693;
  --serviceCard_left: #5fe44b1c;
  --serviceCard_right: #357e293c;
  --blue_hr: #082139;
  --limeBox_left: #12dce8;
  --limeBox_right: #7de82e;
  --limeBox_grad: linear-gradient(
    to right,
    var(--limeBox_left),
    var(--limeBox_right)
  );
  --accordGrad_left: #081718;
  --accordGrad_right: #0c231a;
  --accordGrad: linear-gradient(
    to right,
    var(--accordGrad_left),
    var(--accordGrad_right)
  );
  --plans_border: #49ce87;
  --opCard_border: #08b782;
  --opCard_profit: #4caf50;
  --opCard_botBorder: #5de25a;
  --reviewCard_divider: #073a6c;
  --gold: #ffce05;
  --experCard_gradTop: #051e36;
  --experCard_gradBot: #082a4b;
  --expertCard_grad: linear-gradient(
    ro tight,
    var(--experCard_gradTop),
    var(--experCard_gradBot)
  );
  --footerLogo_bg: #081829;
  --footerLogo_color: #081829;
  --footer_inpBorder: #d8d8d8;
  --light_pink: #e4e6fb;
  --light_green: #dcffe6;
  --light_sky: #baf6ff;
  --dark_blue: #1a28a6;
  --dark_green: #00b132;
  --dashboard__box: #081929;
  --dash_newGreen: #03c894;
  --dash_transact: #09f9f5;
  --placeholder_text: #1e3a56;
  --tableHead_bg: #0b2946;
  --privacy_grayBox : #f7f9fd;
  --small_fontsize: 8px;
  --lower_fontsize: 10px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fourteen: 14px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --higher_fontsize: 25px;
  --font_thirty: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --countdown_fontsize: 45px;
  --font_fifty: 50px;
  --font_sixty: 60px;
  --font_sixtyfive: 65px;
  --font_seventy: 70px;
  --poster_fontsize: 130px;
  --weight_three: 300;
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
}

/* font import */
@font-face {
  font-family: "hebrew_reg";
  src: url("../fonts/hebrew/hebrew_reg.otf");
}
@font-face {
  font-family: "hebrew_strong";
  src: url("../fonts/hebrew/hebrew_strong.otf");
}
@font-face {
  font-family: "hebrew_bold";
  src: url("../fonts/hebrew/hebrew_bold.otf");
}
@font-face {
  font-family: "hebrew_extra_bold";
  src: url("../fonts/hebrew/hebrew_extra_bold.otf");
}
/* end of font import */
/* common css */
.mirza_font {
  font-family: "Mirza", serif !important;
}
.hebrew_font {
  font-family: "hebrew_reg" !important;
}
.hebrew_strong {
  font-family: "hebrew_strong" !important;
}
.hebrew_bold {
  font-family: "hebrew_bold" !important;
}
.hebrew_extra_bold {
  font-family: "hebrew_extra_bold" !important;
}
.common_bg {
  background-color: var(--bg_color);
  overflow-x: hidden;
}
.primary_grad_btn {
  border: none;
  outline: none;
  background-image: var(--gradient_btn);
  color: var(--black);
  font-weight: var(--weight_seven);
  font-size: var(--font_thirteen);
  padding: 8px 15px;
  white-space: nowrap;
}
.primary_grad_btn:hover {
  background-image: var(--gradient_btnInvert);
}
.primary_gradBtnWidth {
  min-width: 120px;
}
.secondary_btn {
  border: none;
  outline: none;
  background: var(--gradient_btn);
  position: relative;
  padding: 8px 20px;
}
.secondary_btn:hover::before,
.secondary_btn:hover::after {
  background-color: transparent;
  color: var(--black);
}
.secondary_btn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--bg_color);
  border-radius: 5px;
}
.secondary_btn::after {
  content: attr(data);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: relative;
  z-index: 2;
  top: -3px;
}
.white_btn {
  border: none;
  outline: none;
  background-image: var(--white);
  color: var(--black);
  font-weight: var(--weight_seven);
  font-size: var(--font_thirteen);
  padding: 8px 15px;
}
.white_btn:hover {
  background-color: var(--radiant_green);
}
.higher_top {
  margin-top: 100px;
}
.higher_bottom {
  margin-bottom: 100px;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--radiant_green);
  border-radius: 10px;
}

/* end of common css */
/* header css */
.header {
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 10;
}
.header__scrolled {
  background-color: var(--bg_color);
  position: fixed;
  top: 0;
  z-index: 10;
}
.landing__logo {
  height: 25px;
  width: 100px;
}
.header__links_holder :is(ul, li) {
  list-style-type: none;
  padding-left: 0;
}
.header__links_holder a,
.header__links_holder li {
  font-size: var(--font_thirteen);
  text-decoration: none;
  color: var(--white);
  white-space: nowrap;
}
.header__links_holder a:hover,
.header__links_holder li:hover {
  color: var(--radiant_green);
}
.header__offcanva.offcanvas {
  background-color: var(--bg_color);
}
.header__canvaCloser {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
/* end of header css */
/* landing css */
.home__topRadiantBlur {
  position: absolute;
  top: -110px;
  height: 400px;
  width: 300px;
  left: 17%;
  transform: rotate(90deg);
  z-index: 0;
}
.home__indexAligner {
  z-index: 5;
}
.home__leftRadiantBlur {
  position: absolute;
  height: 500px;
  width: 300px;
  left: 0;
  top: 9.5%;
  z-index: 0;
}
.home__middleGreenBlur {
  position: absolute;
  height: 350px;
  width: 350px;
  left: 38%;
  top: 7%;
  z-index: 0;
}
.home__rightGreenBlur {
  position: absolute;
  height: 450px;
  width: 400px;
  right: -2%;
  top: 0%;
  z-index: 0;
}
.home__serviceMiddleGreenBlur {
  position: absolute;
  height: 450px;
  width: 450px;
  left: 30%;
  top: -145px;
  z-index: 0;
}
.home__easyMiddleGreenBlur {
  position: absolute;
  height: 450px;
  width: 450px;
  left: 30%;
  top: -200px;
  z-index: 0;
}
.home__easyleftRadiantBlur {
  position: absolute;
  height: 500px;
  width: 450px;
  left: -4%;
  top: 25%;
  z-index: 0;
}
.home__easyrightRadiantBlur {
  position: absolute;
  height: 500px;
  width: 450px;
  right: -8%;
  top: 31%;
  z-index: 0;
  transform: rotate(180deg);
}
.home__acheieveLeftGreenBlur {
  position: absolute;
  height: 350px;
  width: 350px;
  left: -8%;
  top: 42%;
  z-index: 0;
  opacity: 0.7;
  transform: rotate(180deg);
}
.home__serviceLeftGreenBlur {
  position: absolute;
  height: 450px;
  width: 400px;
  left: -15%;
  top: 400%;
  opacity: 0.7;
  transform: rotate(180deg);
  z-index: 0;
}
.home__unleashleftRadiantBlur {
  position: absolute;
  height: 550px;
  width: 350px;
  left: -3%;
  top: 48%;
  opacity: 0.7;
  z-index: 0;
}
.home__faqrightGreenBlur {
  position: absolute;
  height: 400px;
  width: 350px;
  right: -10%;
  top: -30%;
  opacity: 0.5;
  z-index: 0;
}
.home__reviewLeftGreenBlur {
  position: absolute;
  height: 350px;
  width: 350px;
  right: -8%;
  top: 66%;
  z-index: 0;
  opacity: 0.4;
}
.home__reviewleftRadiantBlur {
  position: absolute;
  height: 550px;
  width: 350px;
  left: -5%;
  top: 73%;
  opacity: 0.4;
  z-index: 0;
}
.home__faqRightGreenBlur {
  position: absolute;
  height: 450px;
  width: 450px;
  right: -8%;
  top: 86%;
  z-index: 0;
  opacity: 0.6;
}
.home__bannerSec {
  min-height: 100vh;
  margin-top: 68px;
}
.home__bannerSoar {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
  position: relative;
  top: 25px;
}
.home__bannerGradient {
  font-size: var(--font_sixtyfive);
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home__bannerTitle {
  font-size: var(--font_fifty);
  color: var(--white);
}
.home__bannerHint {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.home__marqueeImg {
  height: 35px;
  min-height: 35px;
  max-height: 35px;
  max-width: 175px;
}
.home__greenBoxHint {
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_eight);
  position: relative;
  top: 5px;
}
.home__greenBoxTitle {
  color: var(--white);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_eight);
}
.home__greenBoxDesc {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_five);
}
.home__greenBox {
  background-image: linear-gradient(
    to right,
    var(--greenBox_left),
    var(--greenBox_right)
  );
  overflow: hidden;
}
.home__greenBoxImg {
  position: absolute;
  opacity: 0.2;
  height: 270px;
  width: 100%;
}

/* service card css */
.home__serviceCard {
  border: 1px solid var(--border);
  min-height: 165px;
}
.home__serviceCard:hover {
  background-image: linear-gradient(
    to right,
    var(--serviceCard_left),
    var(--serviceCard_right)
  );
  cursor: pointer;
}
.home__serviceCardImg {
  height: 40px;
  width: 40px;
}
.home__serviceCardTitle {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.home__serviceCardHint {
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_four);
}
/* service card css */

/* new service css */
.home__serviceGrad {
  font-size: var(--font_sixty);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    var(--service_gradTop) 36%,
    var(--bg_color) 75%
  );
}
.home__newServiceTitle {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
  position: relative;
  top: -20px;
}
.home__serviceHint {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
/* end of new service css */

.home__popularityCount {
  color: var(--white);
  font-size: var(--font_fourty);
}
.home__popularityHolder::after {
  content: "";
  height: 85%;
  width: 4px;
  border-radius: 10px;
  background-color: var(--blue_hr);
  position: absolute;
  right: 0;
  top: 15%;
}
.home__rowBorderBot {
  border-bottom: 5px solid var(--blue_hr);
}

.home__popularityHint {
  word-spacing: 4px;
}
.home__faqHint {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.home__faqNewHint {
  line-height: 2.2;
}
.home__faqTitle {
  line-height: 1.6;
}
.home__accordion .accordion-item {
  border: none !important;
}
.accordion-flush.home__accordion .accordion-item .accordion-button,
.home__accordion.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 5px !important;
}
.home__accordion .accordion-header,
.home__accordion .accordion-item {
  box-shadow: none;
  background: transparent !important;
  border-radius: 5px !important;
}
.home__accordion .accordion-header button {
  box-shadow: none;
  background: transparent !important;
  border: 1px solid var(--border);
  border-radius: 5px !important;
  color: var(--white) !important;
  font-size: var(--font_fifteen) !important;
  font-weight: var(--weight_six) !important;
}
.home__accordion .accordion-button:not(.collapsed) {
  background-image: var(--accordGrad) !important;
}
.home__accordion .accordion-body {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  padding: 15px 0px !important;
}
.home__accordion .accordion-button::after {
  background-image: url("../Images/accord_plus.svg");
  background-size: contain;
}
.home__accordion .accordion-button:not(.collapsed)::after {
  background-image: url("../Images/accord_minus.svg");
  background-size: contain;
}
.home__limeBoxTitle {
  color: var(--black);
  font-size: var(--font_thirtyfive);
  font-weight: var(--weight_eight);
}
.home__limeBoxHint {
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.home__limeBox {
  background-image: var(--limeBox_grad);
  overflow: hidden;
}
.home__radiantRound {
  position: absolute;
  top: 5%;
  left: 1%;
  height: 220px;
  width: 200px;
}
.home__greenRound {
  position: absolute;
  top: 5%;
  right: 0;
  height: 220px;
  width: 200px;
}
.home__copyrights {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.home__copyrightBorder {
  border-top: 2px solid var(--bottom_hr);
}
.home__angleTitle {
  color: var(--radiant_green);
}
.home__angleBotBarHolder {
  position: absolute;
  top: 50%;
}
.home__angleBotBar {
  width: 50%;
  height: 2px;
  background-color: var(--white);
}
.home__angleVertBar {
  height: 235px;
  width: 2px;
  background-color: var(--white);
}
.home__angleVertHolder {
  position: absolute;
  width: 90px;
  left: 46%;
}
.home__triangleLeft {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 43.8%;
}
.home__triangleRight {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 51.7%;
}
/* reviewCard css */
.reviewCard {
  border-width: 1px;
  border-style: solid;
  border-image: var(--gradient_btn) 1;
  border-radius: 10px !important;
  max-width: 550px;
}

.reviewCard__desc {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.reviewCard__blueLiner {
  border-bottom: 1px solid var(--reviewCard_divider);
  width: 90%;
}
.reviewCard__name {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}
.reviewCard__starHolder {
  color: var(--gold);
}
.home__reviewCardAlign {
  flex-wrap: nowrap !important;
}
/* end of reviewCard css */
/* expertiseCard css */
.home__experticeHorCard {
  border: 1px solid var(--experCard_gradBot);
  border-radius: 10px !important;
}
/* end of expertiseCard css */
.home__vertText {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.home__verticalCard {
  border: 1px solid var(--border);
  cursor: pointer;
}
.home__topMarqueeHolder svg{
  fill: var(--white);
  color: var(--white);
}
/* end of landing css */

/* plans css */
.plans__holder {
  margin-top: 68px;

}
.plans__title {
  font-size: var(--font_thirtyfive);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant) 45%,
    var(--radiant_green) 50%
  );
}
.plans__titleHint {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.plans__borderBox {
  border: 1px solid var(--plans_border);
}
.plans__boxTitle {
  font-size: var(--font_thirty);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant) 45%,
    var(--radiant_green) 50%
  );
}
.plansCard__status {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.plansCard__hint {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.plansCard__statusSymbol {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
  font-style: italic;
}
.plansCard__desc {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.plansCard__price {
  color: var(--white);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_seven);
}
.plansCard {
  background-image: url("../Images/planscard_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.plans__swiperPrevBtn {
  position: absolute;
  top: 48%;
  border: none;
  outline: none;
  background-color: transparent;
  left: -2%;
  width: fit-content;
  z-index: 10;
}
.plans__swiperPrevBtn img {
  height: 30px;
  width: 30px;
  transform: rotate(180deg);
}
.plans__swiperNextBtn {
  position: absolute;
  top: 48%;
  border: none;
  outline: none;
  background-color: transparent;
  right: -2%;
  width: fit-content;
  z-index: 10;
}
.plans__swiperNextBtn img {
  height: 30px;
  width: 30px;
}
.plans__swiper .swiper-button-prev,
.plans__swiper .swiper-button-next {
  display: none;
}
.plans__skipper {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.plans__skipper i {
  color: var(--plans_border);
}
.plans__middleBlur {
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 500px;
}
.plans__rightBlur {
  position: absolute;
  top: 10%;
  right: 0;
  height: 500px;
  width: 500px;
}
/* end of plans css */

/* opportunity css */
.opportunity__title {
  width: fit-content;
  font-size: var(--higher_fontsize);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant_green) 30%,
    var(--radiant) 60%
  );
}
.opportunityCard__topSec {
  border: 1px solid var(--opCard_border);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom: none;
  cursor: pointer;
}
.opCard__coinImg {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  max-height: 50px;
  max-width: 50px;
}
.opCard__CoinName {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.opCard__transact {
  color: var(--white);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_eight);
}
.opCard__profit {
  font-size: var(--font_fifteen);
  color: var(--white);
  font-weight: var(--weight_five);
}
.opCard__profit span {
  color: var(--opCard_profit);
}
.opCard__profilHolder {
  border: 1px solid var(--opCard_botBorder);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
/* end of opportunity css */

/* footer css */
.footer__logo {
  height: 50px;
  width: 120px;
}
.footer__desc {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.footer__socialLink {
  border: none;
  outline: none;
  background-color: var(--footerLogo_bg);
  color: var(--footerLogo_color);
  font-size: var(--font_thirteen);
  height: 30px;
  width: 30px;
}
.footer__socialLink i {
  color: var(--bg_color);
}
.footer__socialLink svg {
  fill: var(--bg_color);
  height: 18px;
  width: 18px;
}
.footer__socialLink:hover {
  background-image: var(--gradient_btn);
  color: var(--black);
}
.footer__socialLink:hover i,
.footer__socialLink:hover svg {
  color: var(--black);
  fill: var(--black);
}
.footer__links,
.footer__links li {
  list-style-type: none;
  padding-left: 0;
}
.footer__links li,
.footer__links a {
  text-decoration: none;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
  cursor: pointer !important;
}
.footer__links li:hover,
.footer__links a:hover {
  color: var(--radiant_green);
}
.footer__newsletterImg {
  height: 50px;
  width: 70px;
}
.footer__newsletterBold {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.footer__newsletterDesc {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.footer__subsMail {
  border: 1px solid var(--footer_inpBorder);
  outline: none;
  background-color: var(--white);
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  padding: 6px 15px;
}
.footer__botSecHolder {
  border-top: 1px solid var(--bottom_hr);
}
/* end of footer css */
/* dashboard css */
.dashboard__midGrad {
  width: fit-content;
  font-size: var(--font_twenty);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant) 30%,
    var(--radiant_green) 60%
  );
}
.dashboard__boxTitle {
  color: var(--dark_blue);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.dashboard__blueTitle {
  color: var(--dark_blue);
}
.dashboard__greenTitle {
  color: var(--dark_green);
}
.dashboard__tradeValue {
  color: var(--black);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
.dashboard__dollarValue {
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.dashboard__pinkBox {
  background-color: var(--light_pink);
}
.dashboard__greenBox {
  background-color: var(--light_green);
}
.dashboard__skyBox {
  background-color: var(--light_sky);
}
.dashboard__semiGrad {
  width: fit-content;
  font-size: var(--font_eighteen);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant) 30%,
    var(--radiant_green) 60%
  );
}
.dashboard__label {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.dashboard__smallText {
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.dashboard__blueBox {
  background-color: var(--dashboard__box);
  position: relative;
}
.dashboard__blackBox {
  background-color: var(--bg_color);
}
.dashboard__greenLabel {
  color: var(--dash_newGreen);
}
.dashboard__smallGrad {
  width: fit-content;
  font-size: var(--font_fifteen);
  -webkit-text-fill-color: #0000;
  -webkit-background-clip: text;
  background-image: linear-gradient(
    90deg,
    var(--radiant) 30%,
    var(--radiant_green) 60%
  );
}
.dashboard__profit {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_six);
}
.dashboard__profit span {
  color: var(--opCard_profit);
}
.dashboard__transactAct {
  color: var(--dash_transact);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_eight);
}
.dashboard__dualCardFlex {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.dashboard__balance {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.market_select_component {
  padding: 8px;
  background-color: #151515;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-family: "hebrew_reg";
  font-weight: var(--weight_five);
}
.market_select_component:hover {
  background-color: var(--radiant_green);
  color: var(--black);
}
.dashboard__selectedImg {
  position: absolute;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  top: 25%;
}
.home__dashboardLeftBlur {
  position: absolute;
  top: -2%;
  left: 0%;
  height: 450px;
  width: 450px;
}
.home__dashboardRightBlur {
  position: absolute;
  bottom: 20%;
  right: 0%;
  height: 450px;
  width: 450px;
  transform: rotate(180deg);
}
/* end of dashboard css */

/* ticket management css */
.ticketManage__question {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.ticketManage__input {
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  background-color: var(--bg_color);
  padding: 7px 50px 7px 10px;
}
/* end of ticket management css */
/* transaction history css */

.transacHistory__input {
  border: 0.5px solid var(--radiant_green);
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  background-color: var(--bg_color);
  padding: 7px 10px;
}
.transacHistory__input::placeholder {
  color: var(--placeholder_text);
}
.transacHistory__tableHolder .table-head.asrt-table-head {
  display: none !important;
}
.transacHistory__tableHolder .table-bordered > :not(caption) > * > *,
.transacHistory__tableHolder thead tr,
.transacHistory__tableHolder tbody tr {
  border-width: 0 !important;
}

.transacHistory__tableHolder thead tr th {
  white-space: nowrap !important;
  background-color: var(--tableHead_bg);
  background-image: none !important;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  font-family: "hebrew_strong" !important;
  padding-bottom: 15px;
  padding-top: 10px;
}
.transacHistory__tableHolder tbody tr td {
  background-color: transparent !important;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  font-family: "hebrew_reg" !important;
}

.transacHistory__tableHolder .pagination .disabled > .page-link,
.transacHistory__tableHolder .pagination .page-link.disabled {
  background-color: transparent !important;
  color: var(--accord_border) !important;
  border: none !important;
  box-shadow: none !important;
}
.transacHistory__tableHolder .pagination .page-link {
  background-color: transparent !important;
  color: var(--white) !important;
  border: none !important;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
  font-family: "hebrew_strong" !important;
  box-shadow: none !important;
}
.transacHistory__tableHolder .table-body.asrt-table-body {
  overflow-x: auto;
}
.transacHistory__tableHolder {
  overflow-x: hidden;
}

/* end of transaction history css */

/* modal css */
.cmn__modal {
  background-color: var(--footerLogo_bg);
}
.modal__closer {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
  cursor: pointer;
}
.modal__coinName {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_six);
}
.cmnModal_inpLabel {
  min-width: 20%;
}
.modal__inputAbs {
  color: var(--placeholder_text);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: absolute;
  right: 5%;
  top: 25%;
}
.cmnModal__inpCoinImg {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 5%;
  top: 23%;
}
.modal__inputAbsDollar {
  color: var(--placeholder_text);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: absolute;
  right: 5%;
  top: 40%;
}
.cmnModal_secondaryBtn::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--footerLogo_bg);
  border-radius: 20px;
}
.cnctWallet__singleBox{
  cursor: pointer;
}
/* end of modal css */
/* media queries */
@media(width >= 1400px){
  .home__triangleRight{
    left: 50.9%;
  }
  .home__triangleLeft{
    left: 44.1%;
  }
}
@media (992px <= width < 1200px) {
  .home__angleBotBarHolder {
    top: 47%;
  }
  .home__triangleLeft {
    left: 42.4%;
  }
  .home__triangleRight {
    left: 52.4%;
  }
  .home__angleVertBar {
    height: 280px;
  }
  .home__angleVertHolder {
    left: 45%;
    width: 96px;
  }
}
@media (width >= 992px) {
  .home__networkTitle {
    max-width: 75%;
    line-height: 1.5;
  }
}
@media (768px <= width < 992px) {
  .home__limeBoxTitle {
    font-size: var(--font_thirty);
  }
  .home__limeBoxHint {
    font-size: var(--font_fifteen);
  }
  .home__bannerTitle {
    font-size: var(--font_fourty);
  }
  .home__angleBotBarHolder {
    top: 47%;
  }
  .home__triangleLeft {
    left: 39.6%;
  }
  .home__triangleRight {
    left: 53%;
  }
  .home__angleVertBar {
    height: 320px;
  }
  .home__angleVertHolder {
    left: 43%;
    width: 98px;
  }
}
@media (width < 768px) {
  .home__copyrights {
    font-size: var(--font_thirteen);
  }
  .home__angleCard {
    padding: 15px;
    border: 1px solid var(--white);
    border-radius: 10px;
    min-height: 150px;
  }
}
@media (576px <= width < 768px) {
  .home__greenBoxDesc {
    font-size: var(--font_sixteen);
  }
  .home__limeBoxTitle {
    font-size: var(--higher_fontsize);
  }
  .home__limeBoxHint {
    font-size: var(--font_thirteen);
  }
  .home__angleCardDesc {
    font-size: var(--font_thirteen);
  }
}
@media (width < 576px) {
  .header__loginBtn {
    font-size: var(--lower_fontsize);
    padding: 6px 10px;
  }
  .home__bannerSoar {
    font-size: var(--font_thirteen);
    top: 15px;
  }
  .home__bannerGradient {
    font-size: var(--font_fourty);
  }
  .home__bannerTitle {
    font-size: var(--font_thirty);
  }
  .home__greenBoxDesc {
    font-size: var(--font_fifteen);
  }
  .home__popularityCount,
  .home__greenBoxTitle {
    font-size: var(--font_twenty);
  }
  .home__serviceCardTitle {
    font-size: var(--lower_fontsize);
  }
  .home__serviceCardHint {
    font-size: var(--font_thirteen);
  }
  .home__limeBoxTitle {
    font-size: var(--font_twenty);
  }
  .home__limeBoxHint {
    font-size: var(--font_twelve);
  }
  .home__angleTitle,
  .plans__boxTitle {
    font-size: var(--font_twenty);
  }
  .plans__title {
    font-size: var(--higher_fontsize);
  }
  .privacy__midGrad{
    font-size: var(--font_sixteen);
  }
}
@media (width < 450px) {
  .landing__logo {
    height: 20px;
    width: 80px;
  }
}

/* end of media queries */

#spin {
  /* color:red; */
  background: var(--gradient_text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#spin:after {
  content: "";
  animation: spin 5s linear infinite;
}
@keyframes spin {
  0% {
    content: "AI Power";
  }
  25% {
    content: "Flash Loan";
  }
  50% {
    content: "Arbitrage Trading";
  }
  75% {
    content: "DEFI’s";
  }
  100% {
    content: "DEX’s";
  }
}
.home__blogCard {
  border: 1px solid var(--border);
  border-radius: var(--bs-border-radius-xl) !important;
}
.home__blogCardImg {
  min-height: 170px;
  max-height: 170px;
  width: 100%;
  object-fit: cover;
}
.home__blogCardTitle {
  color: var(--white);
  font-size: var(--font_sixteen);
  text-align: left;
}
.home__blogCardHint {
  color: var(--sec_white);
  font-size: var(--font_fourteen);
  text-align: justify;
}

.green_link {
  color: var(--radiant_green);
  text-transform: uppercase;
  font-size: var(--font_fourteen);
  text-decoration: none;
  font-weight: var(--weight_five);
}
.green_link:hover {
  color: var(--radiant);
}
.innerpages_ui{
  min-height: 80vh;
}